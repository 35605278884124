// extracted by mini-css-extract-plugin
export var card = "kd_h0";
export var card_btn = "kd_s0";
export var card_descr = "kd_n0";
export var card_header = "kd_k0";
export var card_inner = "kd_p0";
export var card_pic = "kd_l0";
export var card_profileInfo = "kd_r0";
export var card_profilePic = "kd_q0";
export var card_sliderPic = "kd_m0";
export var card_wrapper = "kd_j0";