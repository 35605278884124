import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Icon from '~components/Icon'
import Layout from '~components/Layout'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Navigation } from 'swiper'
import S from '~components/seo'
import { Link } from 'gatsby'
import useHttp from '~hooks/useHttp'
import * as s from './Card.module.scss'

const Card = ({ location: { state } }) => {
  const [card, setCard] = useState({})
  const [user, setUser] = useState({})
  const { request, loading } = useHttp()
  const { t } = useTranslation()
  const { userId, cardId } = state || { userId: '', cardId: '' }

  const getCard = async () => {
    const list = await request(
      `https://refhelpapp.herokuapp.com/cardById/${cardId}`,
      'GET'
    )
    setCard(list)
  }

  const getUser = async () => {
    const newUser = await request(
      `https://refhelpapp.herokuapp.com/user/${userId}`,
      'GET'
    )
    setUser(newUser)
  }

  useEffect(() => {
    getCard()
    getUser()
  }, [state])
  return (
    <Layout>
      {!loading && (
        <>
          <S title={card?.title} />
          <Container as="section" className={s.card_wrapper}>
            <Container className={s.card}>
              <div className={s.card_header}>
                <span className={s.card_info}>
                  {t(`categories.${card?.type}`)}
                </span>
                <div className={s.card_pic}>
                  <Icon name={card?.type} size={35} />
                </div>
                <span className={s.card_info}>
                  {t(`locations.${card?.location}`)}
                </span>
              </div>
              <div className={s.card_inner}>
                <h1>{card?.title}</h1>
                <Swiper
                  loop
                  spaceBetween={30}
                  effect="fade"
                  navigation
                  modules={[EffectFade, Navigation]}
                >
                  {card?.pics?.map((path, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <SwiperSlide key={`p-${i}`}>
                      <img
                        className={s.card_sliderPic}
                        src={path}
                        alt={`title-${i}`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <h2>{t(`card.descr`)}</h2>
                <p className={s.card_descr}>{card?.descr}</p>

                <div className={s.card_profilePic}>
                  {user?.pic ? (
                    <img src={user?.pic} alt="user" />
                  ) : (
                    user?.name && user?.name[0]
                  )}
                </div>
                <div className={s.card_profileInfo}>
                  <Button
                    as={Link}
                    to="/user"
                    state={{ userId }}
                    className={s.card_btn}
                    variant="outline-light"
                  >
                    {t(`card.btn`)}
                  </Button>
                </div>
              </div>
            </Container>
          </Container>
        </>
      )}
    </Layout>
  )
}

export default Card
