import { graphql } from 'gatsby'
import React from 'react'
import Card from '~containers/Card'

const CardPage = ({ location }) => <Card location={location} />

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CardPage
